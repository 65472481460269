














































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_DETAILS } from '../../conservatorship/store/actions';
import { GET_DETAILS } from '../../conservatorship/store/getters';
import { FETCH_CONTACT_PERSONS, GET_CONTACT_PERSONS_BY_CONTACT } from '../store';
import { FETCH_CONTACT, GET_CONTACT_BY_ID } from '../../contact/store';
import { ConservatorshipDetails, FetchDetails } from '../../conservatorship/types';
import { ContactPerson } from '../types';
import { Contact } from '../../contact/types';
import { ApiResponse } from '@/components/types';
import ContactPersonsDialog from '@/modules/contact/components/ContactPersonsDialog.vue';
import PhoneRow from '@/components/PhoneRow.vue';
import CardRow from '@/components/CardRow.vue';
import UpdateContactDialog from '@/modules/contact/components/UpdateContactDialog.vue';
import AssignContactPersonDialog from '../components/AssignContactPersonDialog.vue';
import RemoveContactPersonDialog from '../components/RemoveContactPersonDialog.vue';

const Contact = namespace('contact');
const Conservatorship = namespace('conservatorship');
const ConservatorshipToContact = namespace('conservatorshipToContact');

@Component({
  components: {
    RemoveContactPersonDialog,
    AssignContactPersonDialog,
    UpdateContactDialog,
    ContactPersonsDialog,
    PhoneRow,
    CardRow
  }
})
export default class ManageContactPersons extends Vue {
  @Conservatorship.Action(FETCH_DETAILS) fetchConservatorship!: (params: FetchDetails) => Promise<ApiResponse>;
  @Conservatorship.Getter(GET_DETAILS) getConservatorship!: (conservatorship_id: string) => ConservatorshipDetails | undefined;

  @ConservatorshipToContact.Action(FETCH_CONTACT_PERSONS) fetchPersons!: (conservatorship_id: string) => Promise<ApiResponse>;
  @ConservatorshipToContact.Getter(GET_CONTACT_PERSONS_BY_CONTACT) getPersons!: (conservatorship_id: string, contact_id: string) => ContactPerson[];

  @Contact.Action(FETCH_CONTACT) fetchContact!: (contact_id: string) => Promise<ApiResponse>;
  @Contact.Getter(GET_CONTACT_BY_ID) getContact!: (contact_id: string) => Contact | undefined;

  updateContact: Contact | null = null;
  removeContactPerson: ContactPerson | null = null;

  get availableContactPersons() {
    if (!this.persons || !this.contact) return [];

    const assigned = this.persons.map((contactPerson) => contactPerson.id);

    return this.contact.contactPersons.filter((contactPerson) => !assigned.includes(contactPerson.id));
  }

  get contact(): Contact | undefined {
    return this.getContact(this.$route.params['contact'] as string);
  }

  get conservatorship() {
    return this.getConservatorship(this.$route.params['conservatorship'] as string);
  }

  get persons() {
    return this.getPersons(this.$route.params['conservatorship'] as string, this.$route.params['contact'] as string);
  }

  async created() {
    try {
      this.fetchContact(this.$route.params['contact'] as string);

      this.fetchConservatorship({ id: this.$route.params['conservatorship'] as string });
      this.fetchPersons(this.$route.params['conservatorship'] as string);
    } catch (e) {
      console.log(e);
    }
  }
}
