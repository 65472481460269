











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ASSIGN_CONTACT_PERSON } from '../store';
import { AssignContactPerson } from '../types';
import { ApiResponse } from '@/components/types';
import { ContactPerson } from '../../contact/types';
import ContactPersonSelect from '../../contact/components/ContactPersonSelect.vue';

const Conservatorship = namespace('conservatorshipToContact');

@Component({
  components: {
    ContactPersonSelect
  }
})
export default class AssignContactPersonDialog extends Vue {
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Prop({ type: String, required: true }) contact!: string;
  @Prop({ type: Array, default: () => [] }) contactPersons!: ContactPerson[];

  @Conservatorship.Action(ASSIGN_CONTACT_PERSON) assignContactPerson!: (params: AssignContactPerson) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: AssignContactPerson = {
    contact_id: this.contact,
    conservatorship_id: this.conservatorship,
    contact_person_id: ''
  };

  @Watch('dialog')
  onDialog(dialog: boolean) {
    if (!dialog) return;

    setTimeout(() => {
      this.value = {
        contact_id: this.contact,
        conservatorship_id: this.conservatorship,
        contact_person_id: ''
      };

      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    });
  }

  async save() {
    this.error = null;

    return this.assignContactPerson(this.value);
  }
}
