
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_CONTACT_PERSON } from '../store';
import { ContactPerson, RemoveContactPerson } from '../types';
import { ApiResponse } from '@/components/types';

const Conservatorship = namespace('conservatorshipToContact');

@Component({})
export default class RemoveContactPersonDialog extends Vue {
  @Prop({ type: Object }) contactPerson!: ContactPerson | null;
  @Prop({ type: String, required: true }) contact!: string;
  @Prop({ type: String, required: true }) conservatorship!: string;

  @Conservatorship.Action(REMOVE_CONTACT_PERSON) removeContactPerson!: (params: RemoveContactPerson) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;

  value: RemoveContactPerson = {
    conservatorship_id: this.conservatorship,
    contact_id: this.contact,
    contact_person_id: ''
  };

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.value.contact_person_id = '';
      this.error = null;
    }, 200);
  }

  @Watch('contactPerson', { immediate: true })
  watchContact(contactPerson: ContactPerson) {
    if (!contactPerson) return;

    this.value.contact_person_id = contactPerson.id;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.removeContactPerson(this.value);
  }
}
